<template>
  <div class="ladies project-component">
    <div class="fullsection">
      <p>
        Participating in Vivatechs Hackathon was easily one of the most exciting and interesting experiences of my life
        !
      </p>
      <br />
      <p>
        For two weeks, my friend and classmate Duc-Thomas and I worked on developing a solution to the problem “The
        future of urban mobility” where the challenge was to limit the use of personal cars in cities.
      </p>
      <br />
    </div>
    <div class="half-section half-section-media">
      <img src="@/assets/projects/vivatech/album/1.png" alt="" />
    </div>
    <div class="half-section half-section-media ">
      <img src="@/assets/projects/vivatech/album/2.png" alt="" />
    </div>
    <div class="full-section">
      <p>
        We came up with two solutions, a dashboard and a mobile app, which we had to present and then pitch as finalists during Vivatech in front of an audience and our jury on one of the main stages ! 
      </p>
    </div>
    <div class="full-section half-section-media ">
      <img src="@/assets/projects/vivatech/album/3.png" alt="" />
    </div>

    <div class="half-section">
      <p>
        I’ll let my pitch speak for itself, and feel free to send me a message to discuss what you think of our project 🚀
      </p>
    </div>
    <div class="half-section ">
      <iframe class="embed-post" src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6945682055674384384" height="895" width="100%" frameborder="0" allowfullscreen="" title="Post intégré"></iframe>
    </div>

    <div class="lesson-box">
      <h2>What did I learn ?</h2>
      <br />
      <p>
        I learnt how to pitch efficiently in front of an audience, and how to come up with an innovative solution that fits a companies target audience. 
      </p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Vivatech",
};
</script>
<style scoped>

</style>